<template>
  <div v-if="isLoading" class="progress-container">
    <div class="progress-bar"></div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.loading);
    return { isLoading };
  },
};
</script>

<style scoped>
/* Progress Bar Container */
.progress-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Slightly thicker for better visibility */
  background: rgba(255, 255, 255, 0.2); /* Subtle background */
  z-index: 1050;
  overflow: hidden;
}

/* Progress Bar Animation */
.progress-bar {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #00c6ff,
    #0072ff,
    #00c6ff
  ); /* Animated gradient */
  animation: loading-animation 1.5s infinite linear;
}

/* Keyframes for smooth animated effect */
@keyframes loading-animation {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h6>{{ heading }}</h6>
        <router-link
          class="btn btn-sm btn-primary me-2"
          :to="{ name: 'CreateShop' }"
        >
          <i class="fa fa-plus" aria-hidden="true"></i> Create Shop
        </router-link>
      </div>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                :class="header.align ? 'text-center' : 'text-left'"
                v-for="header in headers"
                :key="header.id"
              >
                {{ header.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :key="item.id" v-for="item in data">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      :src="item.image"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.name }}</h6>
                  </div>
                </div>
              </td>

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.city }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ item.state }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ item.address }}, {{ item.pincode }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm bg-gradient-success">{{
                  item.status
                }}</span>
              </td>

              <td class="align-middle text-center text-sm">
                <router-link :to="`/shops/${item._id}`">
                  <ArgonButton color="success" size="md" variant="gradient">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </ArgonButton>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "../../components/ArgonButton.vue";
export default {
  components: {
    ArgonButton,
  },
  props: {
    heading: String,
    headers: Array,
    data: Array,
  },
};
</script>

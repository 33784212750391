import axiosInstance from "@/axiosInstance";

const state = {
  brands: [],
  brandDetails: {},
  totalPages: 0,
  currentPage: 1,
};

const actions = {
  async fetchBrands({ commit }, params) {
    try {
      const { pageNo = 1, limit = 10, search = "", category = "" } = params;
      console.log("params==-----", params);
      const response = await axiosInstance.get(
        `/brands?pageNo=${pageNo}&limit=${limit}&category=${category}&search=${search}`
      );
      await commit("setBrands", response?.data?.results?.data);
      await commit("setTotalPages", response?.data?.results?.totalPage);
      await commit("setCurrentPage", Number(response?.data?.results?.pageNo));
      return;
    } catch (error) {
      console.log("error from brands action=====", error);
      return Promise.reject(error);
    }
  },
  async fetchAllBrands({ commit }, params) {
    try {
      const { categoryId = "" } = params;
      const response = await axiosInstance.get(
        `/brands/all?categoryId=${categoryId}`
      );
      await commit("setBrands", response?.data?.results);
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchBrandDetails({ commit }, params) {
    try {
      const { id } = params;
      const response = await axiosInstance.get(`/brands/${id}`);
      await commit("setBrandDetails", response?.data?.results);
      return;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

const mutations = {
  setBrands: (state, brands) => {
    state.brands = brands;
  },
  setTotalPages: (state, totalPages) => {
    state.totalPages = totalPages;
  },
  setCurrentPage: (state, currentPage) => {
    state.currentPage = currentPage;
  },
  setBrandDetails: (state, brandDetails) => {
    state.brandDetails = brandDetails;
  },
};

const getters = {
  allBrands: (state) => state.brands,
  totalPages: (state) => state.totalPages,
  currentPage: (state) => state.currentPage,
  getBrandDetails: (state) => state.brandDetails,
};

export default {
  state,
  getters,
  actions,
  mutations,
};

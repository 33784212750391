<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>{{ heading }}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-xxs font-weight-bolder ps-2"
                :class="[
                  header.align ? 'text-center' : 'text-left',
                  header.padding || '',
                  { sortable: header.sorting },
                ]"
                v-for="header in headers"
                :key="header.id"
                @click="header.sorting ? productSort() : null"
              >
                {{ header.name }}
                <span class="font-weight-bold" v-if="header.sorting">
                  <i :class="sortIcon"></i>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr :key="item.id" v-for="(item, index) in data">
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      :src="item.image"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs mb-0">
                  {{ item.category.name }}
                </p>
              </td>
              <td>
                <p class="text-xs mb-0">{{ item.packing }}</p>
              </td>
              <td>
                <p v-if="editingIndex === index" class="text-xs mb-0">
                  <input
                    class="from-control form-control-sm"
                    v-model="editedQty"
                    type="number"
                    min="0"
                    @input="validatePositive"
                  />
                  <span
                    :class="{
                      'cursor-not-allowed': getLoading,
                      'cursor-pointer': !getLoading,
                    }"
                    @click="updateQty(item?._id, item?.qty)"
                    class="p-3 text-primary"
                    ><i class="fas fa-check-circle fa-lg"></i
                  ></span>
                  <span
                    @click="updateAbort"
                    :class="{
                      'cursor-not-allowed': getLoading,
                      'cursor-pointer': !getLoading,
                    }"
                    class="text-danger"
                  >
                    <i class="fas fa-times-circle fa-lg"></i>
                  </span>
                </p>
                <p
                  v-else
                  :class="[
                    'text-xs mb-0',
                    { 'text-danger font-weight-bold': item.qty < 10 },
                  ]"
                >
                  <span id="qty">{{ item.qty }}</span>
                  <span
                    :class="{
                      'cursor-not-allowed': getLoading,
                      'cursor-pointer': !getLoading,
                    }"
                    :disabled="getLoading"
                    @click="editQty(index, item.qty)"
                    class="ps-3 text-primary"
                    ><i class="fas fa-pen fa-lg"></i
                  ></span>
                </p>
              </td>
              <td>
                <p class="text-xs mb-0">
                  {{ item.sellingPrice }}
                </p>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-sm bg-gradient-success">{{
                  item.status
                }}</span>
              </td>

              <td class="align-middle text-center text-sm">
                <i
                  class="fas fa-trash fa-lg cursor-pointer"
                  @click="deleteRow(item._id)"
                  style="color: #f5365c"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  setup() {
    const editingIndex = ref(null);
    const editedQty = ref(0);
    const editQty = (index, qty) => {
      editingIndex.value = index;
      editedQty.value = qty;
    };
    return {
      editingIndex,
      editedQty,
      editQty,
    };
  },
  props: {
    heading: String,
    headers: Array,
    data: Array,
  },
  data() {
    return {
      sort: 0,
    };
  },
  computed: {
    sortIcon() {
      if (this.sort === -1) {
        return "bi bi-sort-down icon-size";
      } else if (this.sort === 1) {
        return "bi bi-sort-up icon-size";
      } else {
        return "bi bi-arrow-down-up icon-size";
      }
    },
    ...mapGetters(["getLoading"]),
  },
  methods: {
    ...mapActions(["updateQtyAction"]),
    validatePositive(event) {
      if (event.target.value < 0) {
        event.target.value = "";
      }
    },
    async updateQty(id, qty) {
      try {
        if (this.getLoading) return; // Prevent API call if already loading
        const itemQty = this.editedQty || qty;
        const res = await this.updateQtyAction({ id, qty: itemQty });
        this.$emit("filter");
        this.editingIndex = null;
        this.editedQty = 0;
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "success",
          title: res?.message,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },

    updateAbort() {
      this.editingIndex = null;
      this.editedQty = 0;
    },
    deleteRow(id) {
      this.$emit("delete-record", id);
    },
    productSort() {
      if (this.sort === 0) {
        this.sort = 1;
      } else if (this.sort === 1) {
        this.sort = -1;
      } else {
        this.sort = 0;
      }
      this.$emit("sort", this.sort);
    },
  },
};
</script>

<style scoped>
.sortable {
  cursor: pointer;
}
.icon-size {
  font-size: 1.25em;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
</style>

<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Create Shop</p>
            </div>
          </div>
          <div class="card-body">
            <p class="text-uppercase text-sm">Shop Information</p>
            <form @submit.prevent="createShop">
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Name</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    v-model="shop.name"
                    :class="{ 'is-invalid': formError?.name }"
                  />
                  <div class="invalid-feedback">{{ formError?.name }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Address</label
                  >
                  <input
                    type="text"
                    v-model="shop.address"
                    class="form-control"
                    :class="{ 'is-invalid': formError?.address }"
                  />
                  <div class="invalid-feedback">{{ formError?.address }}</div>
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >City</label
                  >
                  <input
                    type="text"
                    v-model="shop.city"
                    class="form-control"
                    :class="{ 'is-invalid': formError?.city }"
                  />
                  <div class="invalid-feedback">{{ formError?.city }}</div>
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >State</label
                  >
                  <input
                    type="text"
                    v-model="shop.state"
                    class="form-control"
                    :class="{ 'is-invalid': formError?.state }"
                  />
                  <div class="invalid-feedback">{{ formError?.state }}</div>
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Pin code</label
                  >
                  <input
                    type="text"
                    v-model="shop.pincode"
                    class="form-control"
                    :class="{ 'is-invalid': formError?.pincode }"
                  />
                  <div class="invalid-feedback">{{ formError?.pincode }}</div>
                </div>
              </div>
              <br />
              <argon-button color="primary" size="sm" class="ms-auto me-2"
                >Save</argon-button
              >
              <argon-button
                @click="cancel"
                type="button"
                color="danger"
                size="sm"
                class="ms-auto"
                >Cancel</argon-button
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    const formError = {};
    return {
      shop: {
        name: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
      },
      formError,
    };
  },
  methods: {
    ...mapActions(["createShopAction"]),
    async createShop() {
      if (this.errorHandler(this.shop)) {
        try {
          const res = await this.createShopAction(this.shop);
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: res?.data?.message,
            didOpen: (toast) => {
              toast.onmouseenter = this.$swal.stopTimer;
              toast.onmouseleave = this.$swal.resumeTimer;
            },
          });
          this.resetForm();
          this.$router.push(`/shops`);
        } catch (error) {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "error",
            title: error,
            didOpen: (toast) => {
              toast.onmouseenter = this.$swal.stopTimer;
              toast.onmouseleave = this.$swal.resumeTimer;
            },
          });
        }
      }
    },

    errorHandler(fields) {
      this.formError = {};
      let isSubmit = true;
      for (let key in fields) {
        if (fields[key] === "") {
          const fieldName = key
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[key] = `${fieldName} is required`;
          isSubmit = false;
        }
      }
      return isSubmit;
    },

    resetForm() {
      this.shop = {
        name: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
      };
    },
    cancel() {
      this.$router.push(`/shops`);
    },
  },
};
</script>

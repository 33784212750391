<script setup>
import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header pb-0">
            <div class="d-flex align-items-center">
              <p class="mb-0">Edit Brand</p>
              {{ getBrandDetails }}
            </div>
          </div>
          <div class="card-body">
            <p class="text-uppercase text-sm">Brand Information</p>
            <form @submit.prevent="updateBrand">
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Name</label
                  >
                  <input
                    class="form-control"
                    type="text"
                    v-model="getBrandDetails.name"
                    :class="{ 'is-invalid': formError?.name }"
                  />
                  <div class="invalid-feedback">{{ formError?.name }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Packing</label
                  >
                  <input
                    type="text"
                    v-model="getBrandDetails.packing"
                    class="form-control"
                    :class="{ 'is-invalid': formError?.packing }"
                  />
                  <div class="invalid-feedback">{{ formError?.packing }}</div>
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >MRP</label
                  >
                  <input
                    type="text"
                    v-model="getBrandDetails.MRP"
                    class="form-control"
                    :class="{ 'is-invalid': formError?.MRP }"
                  />
                  <div class="invalid-feedback">{{ formError?.MRP }}</div>
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Qty/Box (Qty in one case)</label
                  >
                  <input
                    type="text"
                    v-model="getBrandDetails.qtyPerBox"
                    class="form-control"
                    :class="{ 'is-invalid': formError?.qtyPerBox }"
                  />
                  <div class="invalid-feedback">{{ formError?.qtyPerBox }}</div>
                </div>
              </div>
              <br />
              <argon-button color="primary" size="sm" class="ms-auto me-2"
                >Save</argon-button
              >
              <argon-button
                @click="cancel"
                type="button"
                color="danger"
                size="sm"
                class="ms-auto"
                >Cancel</argon-button
              >
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    const formError = {};
    return {
      brand: {
        name: "",
        packing: "",
        MRP: "",
        qtyPerBox: "",
      },
      formError,
    };
  },
  methods: {
    ...mapActions(["fetchBrandDetails"]),
    async updateBrand() {
      if (this.errorHandler(this.getBrandDetails)) {
        try {
          // const res = await this.createShopAction(this.shop);
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: res?.data?.message,
            didOpen: (toast) => {
              toast.onmouseenter = this.$swal.stopTimer;
              toast.onmouseleave = this.$swal.resumeTimer;
            },
          });
          this.resetForm();
          this.$router.push(`/shops`);
        } catch (error) {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "error",
            title: error,
            didOpen: (toast) => {
              toast.onmouseenter = this.$swal.stopTimer;
              toast.onmouseleave = this.$swal.resumeTimer;
            },
          });
        }
      }
    },

    async getBrand() {
      try {
        const brandId = this.$route.params.id;
        const res = await this.fetchBrandDetails({ id: brandId });
        console.log("res=====", res);

        // this.resetForm();
        // this.$router.push(`/brands`);
      } catch (error) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          icon: "error",
          title: error,
          didOpen: (toast) => {
            toast.onmouseenter = this.$swal.stopTimer;
            toast.onmouseleave = this.$swal.resumeTimer;
          },
        });
      }
    },

    errorHandler(fields) {
      this.formError = {};
      let isSubmit = true;
      for (let key in fields) {
        if (fields[key] === "") {
          const fieldName = key
            .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
            .toLowerCase();
          this.formError[key] = `${fieldName} is required`;
          isSubmit = false;
        }
      }
      return isSubmit;
    },

    resetForm() {
      this.shop = {
        name: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
      };
    },
    cancel() {
      this.$router.push({ name: "Brands" });
    },
  },
  created() {
    this.getBrand();
  },
  computed: {
    ...mapGetters(["getBrandDetails"]),
  },
};
</script>
